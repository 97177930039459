const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default function nonce() {
  let text = ''

  for (let i = 0; i < 40; ++i) {
    text += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  return text
}
