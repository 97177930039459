import {
  KEYCLOAK_FEATURE_STORAGE_KEY,
  IS_KEYCLOAK_ENABLED,
  IS_PRODUCTION,
} from '../constants'
import { getIsFeatureEnabledInLocalStorage } from '@je-pc/utils'

export function getIsKeycloakEnabled() {
  // Keycloak should only be enabled in the production environment as determined by the configuration.
  if (IS_PRODUCTION) return IS_KEYCLOAK_ENABLED

  // This configuration pertains to the QA and staging environments.
  // Note that this logic will be phased out following the full Connect service deprecation.
  const isEnabledInLocalStorage = getIsFeatureEnabledInLocalStorage(
    KEYCLOAK_FEATURE_STORAGE_KEY
  )
  if (isEnabledInLocalStorage !== null) return isEnabledInLocalStorage

  return IS_KEYCLOAK_ENABLED
}
