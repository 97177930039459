/**
 * Publisher allows to send and listen for events:
 * between tabs/iframes (via localStorage item update)
 * in the same window (via window Custom Events)
 * */

export default class Publisher {
  constructor(name) {
    this.name = name
  }
  postMessage(topic, message) {
    const payload = { topic, message }
    localStorage.setItem(this.name, JSON.stringify(payload))
    window.dispatchEvent(new CustomEvent(this.name, { detail: payload }))
    setTimeout(() => {
      localStorage.removeItem(this.name)
    }, 0)
  }

  onmessage(topic, callback) {
    const subscriber = (event) => {
      if (event.key !== this.name && event.type !== this.name) return
      const data = event.detail ?? JSON.parse(event.newValue)
      if (data?.topic !== topic) return
      window.removeEventListener('storage', subscriber)
      window.removeEventListener(this.name, subscriber)
      callback(data?.message)
    }
    window.addEventListener('storage', subscriber)
    window.addEventListener(this.name, subscriber)
  }
}
