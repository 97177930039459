export const TENANT = 'uk'
export const ENVIRONMENT = 'qa28'
export const IS_PRODUCTION = false
export const SMARTGATEWAY_URL = 'https://smartgateway-qa28.cogpart.je-labs.com'

export const CONNECT_HOST = 'https://uk-connect-qa28.cogpart.je-labs.com'

export const IS_KEYCLOAK_ENABLED = true
export const KEYCLOAK_API_HOST = 'https://restaurant-portal-keycloak-public.internal.tkwy-staging.io'
export const KEYCLOAK_FEATURE_STORAGE_KEY = 'keycloak'

export const IMPERSONATED_USER_TYPE = 'jet-employee'
export const PARTNER_USER_TYPE = 'restaurant'

export const KEYCLOAK_CONFIG = {
  url: 'https://public-partner-access-keycloak.internal.tkwy-staging.io/auth',
  realm: 'uk',
  clientId: 'partner-centre',
}

export const KEYCLOAK_IMPERSONATION_CONFIG = {
  url: 'https://partner-access-keycloak.internal.tkwy-staging.io/auth',
  realm: 'jet',
  clientId: 'jet-employee',
}
