import UserService from '../user'
import { navigateToByReturnUrl } from '../../utils/navigateTo'

class GlobalAuthService {
  constructor(internalAuthService, userService) {
    this.internalAuthService = internalAuthService
    this.userService = userService

    this.internalAuthService.setGlobalAuthService(this)

    this.internalAuthService.onAuthLogoutHandler = () => {
      this.forget()
      location.assign(location.origin)
    }
  }

  login() {
    return this.internalAuthService.login()
  }

  forget() {
    this.userService.clearRestaurantId()
    this.internalAuthService.clearToken()
  }

  logout(params) {
    this.forget()
    return this.internalAuthService.logout(params)
  }

  async checkAuth() {
    return await this.internalAuthService.checkAuth()
  }

  authenticate() {
    return this.internalAuthService.authenticate()
  }

  isAuthorized() {
    return this.internalAuthService.isAuthorized()
  }

  createMfaActivationUrl(params) {
    return this.internalAuthService.createMfaActivationUrl(params)
  }

  isInAuthIframe() {
    return this.internalAuthService.isInAuthIframe()
  }

  async getValidToken(params) {
    return await this.internalAuthService.getValidToken(params)
  }

  async acceptTermsAndConditions() {
    await this.internalAuthService.acceptTermsAndConditions()
    await this.userService.refreshCurrentUser()
    navigateToByReturnUrl()
  }

  async getCurrentUser(params) {
    try {
      return await this.userService.getCurrentUser(params)
    } catch (e) {
      this.forget()
      return null
    }
  }

  async fetchCurrentUser(params) {
    try {
      return await this.userService.fetchCurrentUser(params)
    } catch (e) {
      this.forget()
      return null
    }
  }

  subscribeToUserUpdates(handler) {
    return this.userService.subscribeToUserUpdates(handler)
  }

  getRestaurantId() {
    return UserService.getRestaurantId()
  }

  async fetchUserRestaurantsList() {
    return this.userService.fetchRestaurantsList()
  }

  async selectUserRestaurant(restaurantId) {
    this.userService.setRestaurantId(restaurantId)
    await this.userService.refreshCurrentUser()
    navigateToByReturnUrl()
  }
}

export default GlobalAuthService
