import ClientOAuth2 from 'client-oauth2'

import { attempt, isError } from 'lodash-es'
import { CONNECT_HOST } from '../../constants'
import Queue from '../../utils/Queue'
import nonce from '../../utils/nonce'
import logger from '../logger'

import AuthPublisher from '../publisher'
import {
  removeCurrentAuthenticationFlow,
  setConnectAuthInProgress,
  removeConnectAuthInProgress,
  getCurrentAuthenticationFlow,
} from '../../utils/authentication-flow'
import { fetchWithToken } from '../../utils/fetchWithToken'

const CONNECT_ID = 'PartnerCentre'
const TOKEN_STORAGE_KEY = 'je.pcweb.token'
const STATE_STORAGE_KEY = 'je.pcweb.state'
const LOGIN_FINISHED_EVENT_KEY = 'je.pcweb.login_finished'
const AUTH_FRAME_ID = 'auth-frame'
const TOKEN_TTL_DIFF_SEC = 30
const SILENT_RENEW_WAIT_MS = 10000
const CURRENT_USER_API = '/api/user/current'
const publisher = new AuthPublisher('je.pcweb.auth.publisher')

class ConnectAuthService {
  constructor() {
    this.connectHost = CONNECT_HOST
    this.client = new ClientOAuth2({
      authorizationUri: `${this.connectHost}/api/authorize`,
      clientId: CONNECT_ID,
      redirectUri: this.getClientRedirectUri(),
    })
  }

  setGlobalAuthService(globalAuthService) {
    this.globalAuthService = globalAuthService
  }

  getClientRedirectUri() {
    const url = new URL(location.href)
    const redirectParam = url.searchParams.get('redirectUrl') || url.pathname
    return `${location.origin}/login?redirectUrl=${redirectParam}`
  }

  async login() {
    try {
      const token = await this.extractTokenFromUrl()
      const { tokenType, accessToken } = token

      const expires = new Date()
      expires.setSeconds(
        expires.getSeconds() +
          parseInt(token.data.expires_in, 10) -
          TOKEN_TTL_DIFF_SEC
      )

      localStorage.setItem(
        TOKEN_STORAGE_KEY,
        JSON.stringify({
          tokenType,
          accessToken,
          expires,
        })
      )
    } catch (localStorageError) {
      throw new Error(`Failed to persist token. ${localStorageError}`)
    } finally {
      removeConnectAuthInProgress()
      publisher.postMessage(LOGIN_FINISHED_EVENT_KEY)
    }
  }

  authenticate(adfsParams) {
    if (!this.isAuthorized()) {
      setConnectAuthInProgress()
      const state = this.setState()
      this.redirectToConnect(state, adfsParams)
      return false
    }

    return true
  }

  checkAuth() {
    return Queue.enqueue(this.checkAuthHandler.bind(this))
  }

  checkAuthHandler() {
    return new Promise(async (resolve, reject) => {
      if (this.isInAuthIframe()) return this.listenLoginFinishing(resolve)

      const token = this.getToken()
      if (!this.isTokenValid(token)) {
        await this.silentRenew()
        const newToken = this.getToken()
        return this.isTokenValid(newToken)
          ? resolve(newToken)
          : reject(new Error('Unauthorised'))
      }
      return resolve(token)
    })
  }

  async fetchCurrentUser(params) {
    const token = await this.getValidToken({
      forceAuthentication: params.forceAuthentication,
      forceRefresh: params.forceRefresh,
    })

    if (!token) return null

    const response = await fetchWithToken({
      host: CONNECT_HOST,
      url: `${CURRENT_USER_API}?timestamp=${Date.now()}`,
      token,
    })

    return await response.json()
  }

  logout(params = {}) {
    const defaultParams = {
      isChangingRestaurant: false,
      emailAsLoginReady: false,
    }

    params = { ...defaultParams, ...params }

    if (params.isChangingRestaurant) {
      const returnUrl =
        location.pathname === '/logout-restaurant' ? '/' : location.href
      const encodedUrl = encodeURIComponent(returnUrl)
      location.assign(
        `${this.connectHost}/login/restaurant?returnUrl=${encodedUrl}`
      )
    } else {
      removeCurrentAuthenticationFlow()

      const logoutUrl = new URL(`${this.connectHost}/logout`)

      if (params.emailAsLoginReady) {
        logoutUrl.searchParams.set('notification', 'emailAsLoginReady')
      }

      location.assign(logoutUrl)
    }
  }

  getToken() {
    const identityJson = localStorage.getItem(TOKEN_STORAGE_KEY)

    if (identityJson === null || !this.isJSON(identityJson)) {
      return null
    }

    const identity = JSON.parse(identityJson)

    const token = this.client.createToken(
      identity.accessToken,
      identity.accessToken,
      identity.tokenType,
      {}
    )
    const identityExpiresDate = new Date(identity.expires)
    if (!identity.expires || isNaN(identityExpiresDate)) {
      return null
    }
    token.expiresIn(identityExpiresDate)
    return token
  }

  clearToken() {
    localStorage.removeItem(TOKEN_STORAGE_KEY)
  }

  listenLoginFinishing(resolve) {
    const listenTimeout = setTimeout(() => {
      resolve(null)
      logger.info('Silent renew info', {
        isIframe: this.isInAuthIframe(),
        connection: navigator?.connection?.downlink,
        currentFlow: getCurrentAuthenticationFlow(),
        location: location.href,
      })
    }, SILENT_RENEW_WAIT_MS)

    publisher.onmessage(LOGIN_FINISHED_EVENT_KEY, () => {
      clearTimeout(listenTimeout)
      const token = this.getToken()
      resolve(token)
    })
  }

  isAuthorized() {
    const token = this.getToken()
    return this.isTokenValid(token)
  }

  isTokenValid(token) {
    return !(token === null || token.expired())
  }

  isJSON(str) {
    return !isError(attempt(JSON.parse, str))
  }

  redirectToConnect(state, { isAdfsUser, hr, restaurantid }) {
    let query

    if (isAdfsUser) {
      query = { home_realm: hr, restaurant_id: restaurantid }
    }

    location.assign(this.client.token.getUri({ state, query }))
  }

  setState() {
    const state = nonce()
    try {
      sessionStorage.setItem(STATE_STORAGE_KEY, state)
    } catch (sessionStorageError) {
      throw new Error(`Failed to load state. ${sessionStorageError}`)
    }

    return state
  }

  async extractTokenFromUrl() {
    let token
    try {
      token = await this.client.token.getToken(location.href, {
        state: sessionStorage.getItem(STATE_STORAGE_KEY),
      })
    } catch (tokenFetchingError) {
      throw new Error(`Token error: ${tokenFetchingError}`)
    }
    return token
  }

  async silentRenew() {
    const state = this.setState()
    let query
    let frame = document.createElement('iframe')
    frame.setAttribute('id', AUTH_FRAME_ID)
    frame.setAttribute('aria-hidden', 'true')
    frame.style.visibility = 'hidden'
    frame.style.position = 'absolute'
    frame.style.width = frame.style.height = frame.style.borderWidth = '0px'
    document.getElementsByTagName('body')[0].appendChild(frame)
    await this.runIframeNavigation(
      frame,
      this.client.token.getUri({ state, query })
    )
    frame.parentNode.removeChild(frame)
    frame = null
  }

  isInAuthIframe() {
    try {
      return window.frameElement?.id === AUTH_FRAME_ID
    } catch (e) {
      return null
    }
  }

  runIframeNavigation(iframe, url) {
    return new Promise((resolve) => {
      /**
       * Listener for silent login finishing
       */
      this.listenLoginFinishing(resolve)
      iframe.setAttribute('src', url)
      iframe.onload = () => {
        /**
         * Case when user is not logged in connect
         */
        if (!iframe.contentDocument) {
          removeCurrentAuthenticationFlow()
          publisher.postMessage(LOGIN_FINISHED_EVENT_KEY)
          return resolve()
        }
      }

      /**
       * Listener for confirm:terms event
       */
      window.addEventListener('confirm:terms', () => this.authenticate(), {
        once: true,
      })
    })
  }

  /**
   * Gets valid token.
   * If token is failed to be fetched due to any reason returns `undefined`.
   * @param {Object} params - Additional parameters.
   * @param {boolean} [params.forceRefresh=false] - Defines whether token is forced to be refreshed via silent renew.
   * @param {boolean} [params.forceAuthentication=true] - Defines whether user is forced to authenticate if valid token failed to be retrieved. For authentication user is redirected to login page.
   * @return {Object|undefined} Valid token.
   */
  async getValidToken(params = {}) {
    try {
      const defaultParams = {
        forceRefresh: false,
        forceAuthentication: true,
      }

      params = { ...defaultParams, ...params }

      if (params.forceRefresh) {
        this.clearToken()
      }

      const validToken = await this.checkAuth()
      return validToken.accessToken
    } catch {
      if (params.forceAuthentication) {
        // There's no way to extract adfs parameters at this point
        // That is everyone at this point will be redirected to login page
        this.authenticate({ isAdfsUser: false })
      }
    }
  }

  createMfaActivationUrl() {
    logger.error(
      new Error(
        'createMfaActivationUrl method is invoked in Connect authentication flow'
      )
    )
  }
}

export default ConnectAuthService
