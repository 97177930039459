const RETURN_SEARCH_PARAM_KEY = 'returnUrl'
const NO_RETURN_PAGES_PATH = ['/logout', '/login', '/logout-restaurant']

export function navigateToWithReturnUrl(pathname = '/') {
  const currentUrl = new URL(window.location)
  const { origin, pathname: currentPathname } = currentUrl

  const navigateToUrl = new URL(origin + pathname)
  const { pathname: navigateToPathname } = navigateToUrl

  if (currentPathname === navigateToPathname) return

  const isReturnSearchParamNeeded =
    !NO_RETURN_PAGES_PATH.includes(currentPathname)
  if (isReturnSearchParamNeeded) {
    const returnPath =
      currentUrl.searchParams.get(RETURN_SEARCH_PARAM_KEY) ?? currentPathname
    navigateToUrl.searchParams.set(RETURN_SEARCH_PARAM_KEY, returnPath)
  }
  window.history.pushState('', null, navigateToUrl)
}

export function navigateToByReturnUrl() {
  const currentUrl = new URL(window.location)
  const { origin } = currentUrl
  const returnSearchParam = currentUrl.searchParams.get(RETURN_SEARCH_PARAM_KEY)

  const useReturnSearchParam =
    returnSearchParam && !NO_RETURN_PAGES_PATH.includes(returnSearchParam)

  const navigateToPathname = useReturnSearchParam ? returnSearchParam : '/'

  const navigateToUrl = new URL(origin + navigateToPathname)
  window.history.pushState('', null, navigateToUrl)
}
