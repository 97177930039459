import { eventBus, globalEventsList, validateEmailAddress } from '@je-pc/utils'
import { BehaviorSubject } from 'rxjs'
import { navigateToWithReturnUrl } from '../../utils/navigateTo'
import { fetchWithToken } from '../../utils/fetchWithToken'
import { CONNECT_HOST, SMARTGATEWAY_URL, TENANT } from '../../constants'
import { getIsConnectDeprecated } from '../../utils/connect-toggle'

const RESTAURANT_ID_KEY = 'je.pcweb.restaurant_id'
const CHOOSE_BUSINESS_PAGE_PATH = '/choose-business'
const TERMS_AND_CONDITION_PAGE_PATH = '/help/terms-and-conditions?accept=true'

const RESTAURANTS_API = '/keycloakintegration/api/restaurants'

class UserService {
  constructor(internalAuthService) {
    this.internalAuthService = internalAuthService
    this.listenRestaurantIdChange()
  }

  #restaurantId = null
  #user = null
  #userObserver = new BehaviorSubject(this.#user)

  get user() {
    return this.#user
  }

  set user(userInfo) {
    this.#user = {
      ...userInfo,
      email: validateEmailAddress(userInfo.name) ? userInfo.name : null,
      isEmailVerified: validateEmailAddress(userInfo.name),
    }
    this.#userObserver.next(this.#user)
  }

  /**
   * Subscribes to updates of the current user data by passing function to handler updates. Initial value of the current user is emitted.
   * @param {Object} handler - Function that will be invoked once current user data is updates.
   * @returns {Object} subscription - Created subscription.
   * @returns {Object} subscription.unsubscribe - Function to unsubscribe from updates for the created subscription.
   */
  subscribeToUserUpdates(handler) {
    const subscription = this.#userObserver.subscribe(handler)
    return { unsubscribe: subscription.unsubscribe.bind(subscription) }
  }

  /**
   * Gets current user.
   * If the user has been previously fetched returns the user from the cache, otherwise fetches current user from the Connect API.
   * If the user is not fetched due to any reason returns `null`.
   * @param {Object} params - Additional parameters.
   * @param {boolean} [params.forceAuthentication=false] - Defines whether authentication should be forced if current user cannot be fetched due to missing authentication. Should be `true` when data about current user is required, and `false` when such data is optional.
   * @param {boolean} [params.forceRefresh=false] - Defines whether user data should be refreshed even if some user info already exist.
   * @return {Object|null} Current user.
   */
  async getCurrentUser(params = {}) {
    const defaultParams = {
      forceAuthentication: false,
      forceRefresh: false,
    }
    params = { ...defaultParams, ...params }

    if (!params.forceRefresh && this.user) return this.user

    const fetchedUser = await this.fetchCurrentUser(params)
    if (!fetchedUser) return null

    this.validateAndSetRestaurant(fetchedUser)
    this.user = fetchedUser

    return this.user
  }

  /**
   * Fetches current user from the Connect API.
   * If the user is not fetched due to any reason returns `null`.
   * @param {Object} params - Additional parameters.
   * @param {boolean} [params.forceAuthentication=false] - Defines whether authentication should be forced if current user cannot be fetched due to missing authentication. Should be `true` when data about current user is required, and `false` when such data is optional.
   * @param {boolean} [params.forceRefresh=false] - Defines whether user data should be refreshed even if some user info already exist.
   * @return {Object|null} Current user.
   */
  async fetchCurrentUser(params = {}) {
    const defaultParams = {
      forceAuthentication: false,
      forceRefresh: false,
    }
    params = { ...defaultParams, ...params }

    return this.internalAuthService.fetchCurrentUser(params)
  }

  async refreshCurrentUser() {
    await this.getCurrentUser({
      forceAuthentication: true,
      forceRefresh: true,
    })
  }

  validateAndSetRestaurant(user) {
    const restaurantId = user.restaurant?.id

    if (this.isInvalidRestaurantId(restaurantId)) {
      this.clearRestaurantId()
      this.internalAuthService.clearToken()
    }

    this.setRestaurantId(restaurantId)
  }

  setRestaurantId(restaurantId) {
    if (!restaurantId) return
    this.#restaurantId = restaurantId
    localStorage.setItem(RESTAURANT_ID_KEY, restaurantId)
  }

  static getRestaurantId() {
    return localStorage.getItem(RESTAURANT_ID_KEY)
  }

  async fetchRestaurantsByConnect() {
    const token = await this.internalAuthService.getValidKeycloakToken()

    const response = await fetchWithToken({
      host: CONNECT_HOST,
      url: RESTAURANTS_API,
      token,
    })

    const { restaurants = [] } = await response.json()
    return restaurants
  }

  async fetchRestaurantsByKeycloak() {
    const token = await this.internalAuthService.getValidKeycloakToken()
    const restaurants = []
    const limit = 100
    let offset = 0
    let total = null

    while (total === null || offset < total) {
      const response = await fetchWithToken({
        host: SMARTGATEWAY_URL,
        url: `/applications/partnerhub/${TENANT}/user/stores?limit=${limit}&offset=${offset}`,
        token,
      })
      const data = await response.json()
      const { stores, total: newTotal } = data
      restaurants.push(...stores)
      // Update total on first iteration
      if (offset === 0) {
        total = newTotal || 0
      }
      // Increment the offset by the limit
      offset += limit
    }

    return restaurants
      .map((item) => ({
        id: item.id,
        name: item.name,
        logoUrl: item.logoUrl,
        description: `${item.location?.streetAdress} ${item.location?.postalCode}`,
      }))
      .sort((a, b) => a.id - b.id)
  }

  async fetchRestaurantsList() {
    return getIsConnectDeprecated()
      ? this.fetchRestaurantsByKeycloak()
      : this.fetchRestaurantsByConnect()
  }

  static redirectUserToRestaurantSelectPage() {
    return navigateToWithReturnUrl(CHOOSE_BUSINESS_PAGE_PATH)
  }

  clearRestaurantId() {
    this.#restaurantId = null
    localStorage.removeItem(RESTAURANT_ID_KEY)
  }

  isInvalidRestaurantId(restaurantId = this.#restaurantId) {
    const storageRestaurantId = UserService.getRestaurantId()
    if (storageRestaurantId && restaurantId) {
      return storageRestaurantId !== restaurantId
    }
    return false
  }

  listenRestaurantIdChange() {
    window.addEventListener('storage', async (event) => {
      const { key, newValue } = event
      if (!newValue || key !== RESTAURANT_ID_KEY) return
      this.checkIsInvalidData()
    })
  }

  checkIsInvalidData() {
    if (this.isInvalidRestaurantId()) {
      const { name, types } = globalEventsList.invalidData
      eventBus.emit(name, { type: types.restaurantId })
      return true
    }
    return false
  }

  static redirectToTermsAndConditionsPage() {
    return navigateToWithReturnUrl(TERMS_AND_CONDITION_PAGE_PATH)
  }
}

export default UserService
