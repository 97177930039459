import { getIsKeycloakEnabled } from '../../utils/keycloack-toggle'
import {
  setupUserAuthenticationFlow,
  isAdfsUserAuthenticationFlow,
  getCurrentAuthenticationFlow,
  isImpersonatedUserAuthenticationFlow,
} from '../../utils/authentication-flow'
import { KEYCLOAK_CONFIG, KEYCLOAK_IMPERSONATION_CONFIG } from '../../constants'

import logger from '../logger'

import GlobalAuthService from './global-auth'
import KeycloakAuthService from './keycloak-auth'
import ConnectAuthService from './connect-auth'
import UserService from '../user'

export const makeAuthService = () => {
  setupUserAuthenticationFlow()

  const isAdfsUser = isAdfsUserAuthenticationFlow()

  const isKeycloakEnabled = getIsKeycloakEnabled()

  const shouldUseKeycloak = isKeycloakEnabled && !isAdfsUser

  const localAuthService = shouldUseKeycloak
    ? new KeycloakAuthService(getKeycloakConfig())
    : new ConnectAuthService()
  const userAuthService = new UserService(localAuthService)

  if (!isAdfsUser && !shouldUseKeycloak) {
    logger.info('Connect auth service used', {
      isAdfsUser,
      isKeycloakEnabled,
      shouldUseKeycloak,
      location: location.href,
      storageFlow: getCurrentAuthenticationFlow(),
    })
  }

  return new GlobalAuthService(localAuthService, userAuthService)
}

const getKeycloakConfig = () => {
  const isImpersonatedUser = isImpersonatedUserAuthenticationFlow()
  return isImpersonatedUser ? KEYCLOAK_IMPERSONATION_CONFIG : KEYCLOAK_CONFIG
}
