import { ENVIRONMENT, TENANT } from '../constants'

const OVERRIDE_SESSION_KEY = 'je.pcweb.connect-deprecated'
const OVERRIDE_SEARCH_PARAM = 'je-pcweb-connect-deprecated'

function setupDeprecationOverride(state) {
  state === 'true' || state === 'false'
    ? sessionStorage.setItem(OVERRIDE_SESSION_KEY, state)
    : sessionStorage.removeItem(OVERRIDE_SESSION_KEY)
}

function getDeprecationOverriddenBySession() {
  const searchParams = new URLSearchParams(window.location.search)
  const overrideSearchParam = searchParams.get(OVERRIDE_SEARCH_PARAM)

  if (overrideSearchParam) {
    setupDeprecationOverride(overrideSearchParam)
  }
  return sessionStorage.getItem(OVERRIDE_SESSION_KEY)
}

export function getIsConnectDeprecated() {
  const deprecationOverriddenBySession = getDeprecationOverriddenBySession()
  if (deprecationOverriddenBySession)
    return deprecationOverriddenBySession === 'true'

  const isDeprecatedByEnv = process.env.CONNECT_DEPRECATED
  const deprecatedEnvironments = process.env.CONNECT_DEPRECATED_ENVIRONMENTS
  if (isDeprecatedByEnv === 'true') return true

  if (deprecatedEnvironments) {
    const config = JSON.parse(deprecatedEnvironments)
    return !!config[ENVIRONMENT]?.includes(TENANT)
  }
  return false
}
