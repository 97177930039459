import { getADFSAbortParams } from './adfs'
import { hasImpersonationParams } from './impersonation'
import { getDecodedKeycloakToken } from './keycloakDataStorage'
import { IMPERSONATED_USER_TYPE } from '../constants'
import ConnectAuthService from '../services/authentication/connect-auth'

const AUTH_FLOW_STORAGE_KEY = 'je.pcweb.auth_flow'
export const ADFS_USER_AUTHENTICATION_FLOW = 'connect_adfs_user'
export const CONNECT_USER_AUTH_IN_PROGRESS = 'connect_user_auth_in_progress'
export const IMPERSONATED_USER_AUTHENTICATION_FLOW =
  'keycloak_impersonated_user'
export const IMPERSONATED_USER_AUTH_IN_PROGRESS =
  'keycloak_impersonated_user_auth_in_progress'

export function setCurrentAuthenticationFlow(authenticationFlow) {
  localStorage.setItem(AUTH_FLOW_STORAGE_KEY, authenticationFlow)
}
export function getCurrentAuthenticationFlow() {
  return localStorage.getItem(AUTH_FLOW_STORAGE_KEY)
}
export function removeCurrentAuthenticationFlow() {
  localStorage.removeItem(AUTH_FLOW_STORAGE_KEY)
}

//connect
export function setConnectAuthInProgress() {
  sessionStorage.setItem(CONNECT_USER_AUTH_IN_PROGRESS, 'true')
}
export function isConnectAuthInProgress() {
  return sessionStorage.getItem(CONNECT_USER_AUTH_IN_PROGRESS)
}
export function removeConnectAuthInProgress() {
  sessionStorage.removeItem(CONNECT_USER_AUTH_IN_PROGRESS)
}

export function isAdfsUserAuthenticationFlow() {
  return getCurrentAuthenticationFlow() === ADFS_USER_AUTHENTICATION_FLOW
}

// keycloak
export function setImpersonatedUserAuthInProgress() {
  sessionStorage.setItem(IMPERSONATED_USER_AUTH_IN_PROGRESS, 'true')
}
export function isImpersonatedUserAuthInProgress() {
  return sessionStorage.getItem(IMPERSONATED_USER_AUTH_IN_PROGRESS)
}
export function removeImpersonatedUserAuthInProgress() {
  sessionStorage.removeItem(IMPERSONATED_USER_AUTH_IN_PROGRESS)
}

export function isImpersonatedUserAuthenticationFlow() {
  const token = getDecodedKeycloakToken()
  const isImpersonatedUserTokenStored = token?.atyp === IMPERSONATED_USER_TYPE

  return (
    isImpersonatedUserTokenStored ||
    hasImpersonationParams() ||
    isImpersonatedUserAuthInProgress()
  )
}

export function setupUserAuthenticationFlow() {
  if (getADFSAbortParams()) {
    removeCurrentAuthenticationFlow()
    removeConnectAuthInProgress()
    return
  }

  if (isConnectAuthInProgress()) {
    setCurrentAuthenticationFlow(ADFS_USER_AUTHENTICATION_FLOW)
    return
  }

  if (isAdfsUserAuthenticationFlow()) {
    const connectAuthService = new ConnectAuthService()
    if (connectAuthService.isAuthorized()) return
    removeCurrentAuthenticationFlow()
  }
}
